import { convertLH3ToLH4 } from 'helpers';

export const linkMap = 'https://goo.gl/maps/zPWhEfv8d9Q1eeTFA';

export const linkFirstBg = convertLH3ToLH4(
  'https://lh3.google.com/u/0/d/1TDSYaCSvhm3m7EZtssr55tipAhaAzlUo=w3470-h1918-iv1',
);
export const couplePic = convertLH3ToLH4(
  'https://lh3.google.com/u/0/d/1v8Q0JzM-tytBdKBjExl82P6N4XwMioXF=w2636-h1918-iv1',
);
export const thankyouLink = convertLH3ToLH4(
  'https://lh3.google.com/u/0/d/1l-0FhHS4YRghPZfxm8ihZ1e6r0_wWWCs=w2636-h1918-iv1',
);
export const bgSaveDateLink = convertLH3ToLH4(
  'https://lh3.google.com/u/0/d/10Jo_mnSuIFAzacluc7QEHyoxrqCLHACb=w2636-h1918-iv1',
);
